import "./sidebar.scss";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { Link } from "react-router-dom";

import { useContext } from "react";

const Sidebar = () => {

    return (
        <div className="sidebar">
            <div className="top">
                <Link to="/" style={{ textDecoration: "none" }}>
                    <span className="logo">Maxwax Admin</span>
                </Link>
            </div>
            <hr />
            <div className="center">
                <ul>
                    <p className="title">Modules</p>
                    <Link to="/modules" style={{ textDecoration: "none" }}>
                        <li>
                            <ViewModuleIcon className="icon" />
                            <span>Modules</span>
                        </li>
                    </Link>
                    <Link to="/sections" style={{ textDecoration: "none" }}>
                        <li>
                            <AutoAwesomeMotionIcon className="icon" />
                            <span>Sections</span>
                        </li>
                    </Link>
                    <Link to="/panels" style={{ textDecoration: "none" }}>
                        <li>
                            <CreditCardIcon className="icon" />
                            <span>Panels</span>
                        </li>
                    </Link>
                    <Link to="/subpanels" style={{ textDecoration: "none" }}>
                        <li>
                            <CreditCardIcon className="icon" />
                            <span>Subpanels</span>
                        </li>
                    </Link>
                    <Link to="/objects" style={{ textDecoration: "none" }}>
                        <li>
                            <DataObjectIcon className="icon" />
                            <span>Objects</span>
                        </li>
                    </Link>
                    <p className="title">Maintenance</p>
                    <li>
                        <PersonOutlineIcon className="icon" />
                        <span>Users</span>
                    </li>
                    <br></br>
                    <li>
                        <ExitToAppIcon className="icon" />
                        <span>Logout</span>
                    </li>
                </ul>
            </div>

        </div>
    );
};

export default Sidebar;

