import { useState } from "react";

function TableRows({rowsData, deleteTableRows, handleChange}) {
    return(
        rowsData.map((data, index)=>{
            const {fullName, emailAddress, salary}= data;
            return(
                <tr key={index}>
                <td>
               <input type="text" value={fullName} onChange={(evnt)=>(handleChange(index, evnt))} name="fullName" className="form-control"/>
                </td>
                <td><input type="text" value={emailAddress}  onChange={(evnt)=>(handleChange(index, evnt))} name="emailAddress" className="form-control"/> </td>
                <td><input type="text" value={salary}  onChange={(evnt)=>(handleChange(index, evnt))} name="salary" className="form-control" /> </td>
                <td><button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>Delete</button></td>
            </tr>
            )
        })
    )
}

function SideNavChildDT () {
    const [rowsData, setRowsData] = useState([]);

    const addTableRows = (e) => {
        e.preventDefault();
        const rowsInput = {
            Value: '',
            ClassName: '',
            Style: ''
        }
        setRowsData([...rowsData, rowsInput])

    }
    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }
    return (
        <div className="container">
            <div className="row">
                <label>Side Nav Children</label><hr />
                <div className="offset-1 col-md-10">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Value</th>
                                <th>Class Name</th>
                                <th>Style</th>
                                <th><button className="btn btn-primary" onClick={addTableRows} >Add New</button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-4">
                </div>
            </div>
        </div>
    )
}
export default SideNavChildDT