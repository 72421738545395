import { useState } from "react";

import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import ChildrenDataTable from './childrendatatable'
import SideNavChildDT from './SideNavChildDT'


const sideNavChildren = [
  { Order: '1', Value: 'Test', ClassName: 'Test', Style: 'fontSize: "30px"' },
  { Order: '2', Value: 'Test2', ClassName: 'Test2', Style: 'fontSize: "30px"' },
  { Order: '3', Value: 'Test2', ClassName: 'Test2', Style: 'fontSize: "30px"' }
]

const testTable = [
  { name: 'Manila', temperature: '37c' },
  { name: 'Baguio', temperature: '20c' }
]

const Row = (props) => {
  const { name, temperature } = props
  return (

    <tr>
      <td>{name}</td>
      <td>{temperature}</td>
    </tr>
  )

}

const Table = (props) => {
  const { data } = props
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Temperature</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map(row =>
          <Row name={row.name} temperature={row.temperature}></Row>
        )}
      </tbody>
    </table>
  )
}

const ObjectCreate = () => {
  // const [rows, setRows] = useState(testTable)
  const [oType, setOType] = useState()

  const handleTypeChange = (e) => {
    setOType(e)
  }

  const renderChildren = (type) => {
    switch (type) {
      case 'sideNav':
        return <SideNavChildDT />
      default:
        break;
    }
  }

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <Card style={{ margin: "20px" }}>
          <Card.Body>
            <h2>Add New Object</h2>
            <br></br>
            <Form>
              <div className="row">
                <Form.Group className="col-md-3" controlId="objectCode">
                  <Form.Label>Object Code</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
                <Form.Group className="col-md-3" controlId="objectName">
                  <Form.Label>Object Name</Form.Label>
                  <Form.Control type="text" placeholder="Type Object Name" />
                </Form.Group>
                <Form.Group className="col-md-3" controlId="objectType">
                  <Form.Label>Object Type</Form.Label>
                  <select class="form-select" aria-label="Default select example" onChange={e => handleTypeChange(e.target.value)}>
                    <option selected>--Select--</option>
                    <option value="sideNav">Side Nav</option>
                    <option value="img">Image</option>
                    <option value="text">Text</option>
                    <option value="button">Button</option>
                  </select>
                </Form.Group>
                <Form.Group className="col-md-3" controlId="objectName">
                  <Form.Label>Subpanel Code</Form.Label>
                  <select class="form-select" aria-label="Default select example">
                    <option selected>--Select--</option>
                  </select>
                </Form.Group>
              </div>
              <br></br>
              <div className="row">
                <Form.Group className="col-md-3" controlId="className">
                  <Form.Label>Class Name</Form.Label>
                  <Form.Control type="text" placeholder="For special css" />
                </Form.Group>
                <Form.Group className="col-md-3" controlId="objectText">
                  <Form.Label>Object Text (For Text Object)</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
                <Form.Group className="col-md-3" controlId="objectStyle">
                  <Form.Label>Object Dynamic Style</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </div>
              <br></br>
              <div className="row">
                {renderChildren(oType)}
              </div>
              <br />
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>

      </div>
    </div>
  );
};

export default ObjectCreate