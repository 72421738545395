const New = () => {
    return (

        <div>
            <h1 className="title">New</h1>
        </div>

    )
}

export default New;