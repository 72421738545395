export const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
        field: "name",
        headerName: "Name",
        width: 230,
    },
    {
        field: "type",
        headerName: "Type",
        width: 230,
    },

    {
        field: "subpanelcode",
        headerName: "Sub Panel Code",
        width: 230
    },
    {
        field: "datecreated",
        headerName: "Date Created",
        width: 160
    },
    {
        field: "dateupdated",
        headerName: "Date Updated",
        width: 160
    }
];

//temporary data
export const userRows = [
    {
        id: 1,
        name: "MaxWaxLogo",
        type: "img",
        subpanelcode: "Panel1",
        datecreated: "January 10, 2022 1:52:23 AM",
        dateupdated: "January 10, 2022 1:52:23 AM",
    },
    {
        id: 2,
        name: "SideNav",
        type: "sideNav",
        subpanelcode: "Panel1",
        datecreated: "January 10, 2022 1:52:23 AM",
        dateupdated: "January 10, 2022 1:52:23 AM",
    },

    {
        id: 3,
        name: "Book an Appointment Button",
        type: "button",
        subpanelcode: "Panel1",
        datecreated: "January 10, 2022 1:52:23 AM",
        dateupdated: "January 10, 2022 1:52:23 AM",
    },
];