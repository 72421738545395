const Single = () => {
    return (

        <div>
            <h1 className="title">Single</h1>
        </div>

    )
}

export default Single;